import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { IoDuplicate, IoCreate } from "react-icons/io5";
import DatabaseContext from '../../contexts/DatabaseContext';
import ModalContext from '../../contexts/ModalContext';
import Delete from '../../../static/images/delete-resume.svg';
import Edit from '../../../static/images/edit-resume.svg';



const menuToggleDataTestIdPrefix = 'resume-preview-menu-toggle-';

const ResumePreview = ({ resume }) => {
  const { t, i18n } = useTranslation();
  const { emitter, events } = useContext(ModalContext);
  const { duplicateResume, deleteResume } = useContext(DatabaseContext);

  const handleOpen = () => navigate(`/app/builder/${resume.id}`);


  const handleDuplicate = () => {
    duplicateResume(resume);
  };

  const handleRename = () => {
    emitter.emit(events.CREATE_RESUME_MODAL, resume);
  };

  const handleDelete = () => {
    deleteResume(resume.id);
    toast(t('dashboard.toasts.deleted', { name: resume.name }));
  };


  return (
    <div className='flex w-full justify-around pb-6'>
      {/* <div className={styles.backdrop}>
        <img src={resume.preview} alt={resume.name} />
      </div> */}
      <div className='h-[300px] w-[35%] border-[1px] border-solid border-[#DEE2E8] rounded-lg flex items-center justify-center' />
      <div className='flex flex-col items-start align-top space-y-10 w-[50%]'>
        <span className='flex flex-col space-y-2'>
          <h3 className='text-[#293F67] font-medium text-[22px]'>{resume.name}</h3>
          {resume.updatedAt && (
            <span className='text-[#97A5BD] font-normal text-[16px]'>
              {t('dashboard.lastUpdated', {
                timestamp: dayjs(resume.updatedAt)
                  .locale(i18n.language.substr(0, 2))
                  .fromNow(),
              })}
            </span>
          )}
        </span>

        <span className='flex flex-col space-y-3'>
          <button
            onClick={handleOpen}
            className='flex space-x-2 items-center'
          >
            <img src={Edit} className='w-6' alt="edit" />
            <p className='text-[18px] text-[#262B33] font-normal'>Edit</p>
          </button>
          <button onClick={handleDuplicate}
            className='flex space-x-2 items-center'
          >
            <IoDuplicate style={{ color: '#3346D3', fontSize: '20px' }} />
            <p className='text-[18px] text-[#262B33] font-normal'>Duplicate</p>
          </button>
          <button onClick={handleRename}
            className='flex space-x-2 items-center'
          >
            <IoCreate style={{ color: '#3346D3', fontSize: '20px' }} />
            <p className='text-[18px] text-[#262B33] font-normal'>Rename</p>
          </button>
          <button onClick={handleDelete}
            className='flex space-x-2 items-center'
          >
            <img src={Delete} className='w-6' alt="delete" />
            <p className='text-[18px] text-[#262B33] font-normal'>Delete</p>
          </button>
        </span>
      </div>
    </div>
  );
};

export default ResumePreview;

export { menuToggleDataTestIdPrefix };
