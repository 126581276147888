import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
// import ReactPixel from 'react-facebook-pixel';
import CreateResume from '../../components/dashboard/CreateResume';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';
import Upgrade from '../../../static/images/upgrade.svg';
import UpgradeIcon from '../../../static/images/upgrade-icon.svg';
import Tick from '../../../static/images/green-tick.svg';
import Create from '../../../static/images/create-resume.png';

const Dashboard = ({ user }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(false);
  const [premium, setPremium] = useState(false);
  const location = useLocation();

  useEffect(async () => {
    // console.log(location.search);
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (location.search === '?success=true') {
      const { uid } = localUser;
      const userRef = firebase.database().ref(`users/${uid}`);
      const userSnapshot = await userRef.once('value');
      const updateUser = userSnapshot.val();
      setPremium(updateUser.isPremiumUser);

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log('new subscription event dev');
        userRef.update({
          isNewSubscription: false,
        });
      } else if (updateUser.isNewSubscription) {
        firebase.analytics().logEvent('new_subscription_created', {
          user_id: uid,
          subscription_id: updateUser.subscriptionId,
          username: updateUser.displayName,
        });
        if (typeof window !== 'undefined') {
          if (window.fbq != null) {
            window.fbq('trackCustom', 'new_subscription_created', {
              user_id: uid,
              subscription_id: updateUser.subscriptionId,
              username: updateUser.displayName,
            });
          }
        }
        // ReactPixel.trackCustom('new_subscription_created', {
        //   user_id: uid,
        //   subscription_id: updateUser.subscriptionId,
        //   username: updateUser.displayName,
        // });
        userRef.update({
          isNewSubscription: false,
        });
      }
    }
  }, [location.search]);

  const openLimit = () => {
    setLimit(true);
  };

  const closeLimit = () => {
    setLimit(false);
  };

  useEffect(async () => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser && user) {
      const { isPremiumUser } = localUser;
      setPremium(isPremiumUser);
      const { uid } = localUser;
      const userRef = firebase.database().ref(`users/${uid}`);
      const userSnapshot = await userRef.once('value');
      userSnapshot.val() &&
        localStorage.setItem('user', JSON.stringify(userSnapshot.val()));

      firebase
        .database()
        .ref(socketRef)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            setLoading(false);
            firebase.database().ref(socketRef).off();
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const resumesArr = [];
            const data = snapshot.val();
            Object.keys(data).forEach((key) => resumesArr.push(data[key]));
            setResumes(resumesArr);
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('child_removed', (snapshot) => {
          if (snapshot.val()) {
            setResumes(resumes.filter((x) => x.id === snapshot.val().id));
          }
        });

      return () => {
        firebase.database().ref(resumesRef).off();
      };
    }
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Helmet>
        <title>{t('dashboard.title')} | SimpleCV</title>
        <link rel="canonical" href="https://simplecv.me/app/dashboard" />
      </Helmet>

      <TopNavbar />

      <div className="container my-12 px-10 ">
        <h1 className="text-[32px] font-semibold text-[#293F67]">
          {t('dashboard.myDocs')}
        </h1>

        <div className="flex h-[54px] justify-between items-center mt-4 mb-6 border-b-[1px] border-[#DDE3EF] border-solid pb-2 ">
          <h1 className="text-[22.6px] text-[#293F67] border-b-[2px] border-[#293F67] border-solid mb-[-24px]">
            {t('dashboard.myResumes')}
          </h1>
          {premium ? (
            ''
          ) : (
            <Link
              to="/pricing/step1"
              className="bg-[#3346D3] text-white py-4 px-6 rounded-lg shadow-lg text-[16px] font-semibold flex items-center justify-center space-x-2"
            >
              <img src={Upgrade} className="w-6" alt="upgrade" />{' '}
              <p>{t('shared.buttons.upgrade')}</p>{' '}
            </Link>
          )}
        </div>

        <div
          className={`${
            limit === true ? 'flex' : 'hidden'
          } absolute justify-center items-center h-full w-full bg-[#2835506b] top-0 left-0`}
        >
          <div className="bg-white flex absolute w-[80%] h-[55%] md:w-[50%] md:h-[45%]  rounded-2xl shadow-xl">
            <button
              onClick={closeLimit}
              className="absolute text-[#CFD6E5] text-[34px] float-right right-4 font-mono font-thin"
            >
              x
            </button>
            <div className="hidden md:flex justify-center items-center w-2/6">
              <img src={UpgradeIcon} alt="upgrade icon" />
            </div>
            <div className="flex flex-col space-y-4 justify-center items-start px-5 w-full md:w-4/6">
              <h1 className="text-[#293F67] font-semibold text-[24px]">
                {t('modals.subscriptionCreate.title')}
              </h1>
              <p className="text-[#53587E] text-[16px]">
                {t('modals.subscriptionCreate.description')}
              </p>

              <p className="text-[#293F67] text-[16px]">
                {t('modals.subscriptionCreate.also')}
              </p>

              <div className="flex space-x-3">
                <img src={Tick} alt="green tick" />
                <p className="text-[#53587E] text-[16px]">
                  {t('modals.subscriptionCreate.features.0')}
                </p>
              </div>
              {/* <div className="flex space-x-3">
                <img src={Tick} alt="green tick" />
                <p className="text-[#53587E] text-[16px]">
                  {t('modals.subscriptionCreate.features.1')}
                </p>
              </div> */}
              <div className="flex space-x-3">
                <Link
                  to="/pricing/step1"
                  className="bg-[#3346D3] p-4 text-white text-[16px] rounded-2xl shadow-md hover:no-underline"
                >
                  {t('shared.buttons.upgrade')}
                </Link>
                <button
                  onClick={closeLimit}
                  className=" p-4 text-[16px] rounded-2xl border-[1px] border-solid border-[#c9c9c9] shadow-md"
                >
                  {t('shared.buttons.skip')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 ">
          {premium === false && resumes.length === 1 ? (
            <div className="flex w-full justify-around pb-6">
              <button
                onClick={openLimit}
                className="h-[300px] w-[35%] border-[1px] border-solid border-[#DEE2E8] rounded-lg flex items-center justify-center cursor-pointer"
              >
                <img src={Create} className="w-24" alt="create" />
              </button>
              <div className="flex flex-col items-start  justify-center w-[50%]">
                <p className="text-[#293F67]  font-medium text-[22px]">
                  {t('dashboard.createResume')}
                </p>
                <p className="text-[#97A5BD] text-[17px] mt-3">
                  {t('dashboard.createResumeDescriptions')}
                </p>
              </div>
            </div>
          ) : (
            <CreateResume resumes={resumes} />
          )}

          {resumes.map((x) => (
            <ResumePreview key={x.id} resume={x} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
