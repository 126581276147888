import { Link } from 'gatsby';
import React, { memo, useContext, useState } from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Avatar from '../shared/Avatar';
import logo from '../../../static/images/logo-main.svg';
import UserContext from '../../contexts/UserContext';

const TopNavbar = () => {
  const location = useLocation();
  const [mobile, setMobile] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const ShowMenu = () => {
    if (mobile === true) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };
  // <div className={styles.navbar}>
  //   <div className="container">
  //     <Link to="/">
  //       <Logo size="40px" />
  //     </Link>

  //     <Avatar className="ml-8" />
  //   </div>
  // </div>
  return (
    <div className="flex justify-around container py-6">
      <div className="flex justify-left space-x-2 items-center w-2/4 md:w-3/12 ml-6">
        {' '}
        <Link
          to="/"
          className="font-semibold  text-[18px] text-[#363C4B] hover:no-underline"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="logo" className="mr-2" />
            SimpleCV
          </div>
        </Link>
      </div>
      <button
        onClick={ShowMenu}
        className="flex w-1/4 items-center align-middle justify-center mr-6 md:hidden rounded-2xl font-semibold bg-[#3346D3] text-white"
      >
        menu
      </button>

      <div
        className={`${
          mobile === true ? 'flex' : 'hidden'
        } absolute flex-col md:flex-row md:relative top-28 md:top-0 md:flex bg-white md:bg-transparent border-2 border-solid md:border-none w-full md:w-9/12 pb-8 md:pb-0`}
      >
        <div className="w-full md:w-[80%] pr-0 md:pr-6 pt-6 md:pt-0 flex-col md:flex-row flex justify-end items-center space-x-0 md:space-x-12 space-y-8 md:space-y-0">
          <Link
            to="/app/dashboard"
            className={`${
              location.pathname === '/app/dashboard'
                ? 'text-[#152B7C]'
                : 'text-[#9294A7]'
            } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
          >
            {t('dashboard.navbar.dashboard')}
          </Link>
          {/* <Link to='/resume' className={`${location.pathname === '/resume' ? 'text-[#152B7C]' : 'text-[#9294A7]'} duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}>Resume</Link> */}
          {user.isPremiumUser ? (
            ''
          ) : (
            <Link
              to="/pricing/step1"
              className={`${
                location.pathname === '/pricing'
                  ? 'text-[#152B7C]'
                  : 'text-[#9294A7]'
              } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
            >
              {t('dashboard.navbar.pricing')}
            </Link>
          )}
        </div>
        <div className="w-full md:w-[20%] flex mt-8 md:mt-0 justify-center md:justify-start items-center space-x-8 md:space-x-4 mr-0 md:mr-2">
          <div className="border-none md:border-solid border-l-[2px] pl-0 md:pl-6">
            <Avatar className="ml-0 md:ml-8" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TopNavbar);
